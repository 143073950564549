import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { CloseOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Lightbox } from 'react-modal-image';
import withScrolling from 'react-dnd-scrolling';

import api from '@services/axios';
import s from '@components/GroupingNew/GroupingNew.module.scss';
import {
  addNewWorkItemAction,
  changeDocumentType,
  cloneWorkItemAction,
  getDocumentsTypes,
  getGroupingLeftSide,
  getWorkItemByFilter,
  getWorkItemDocumentsById,
  onAddUnassignedDocumentAction,
  onDocumentDropAction,
  onMovePageToNewDocumentAction,
  onPageDropAction,
  onRemoveWorkItemAction,
  removeDocument,
  resetGrouping,
  resetWorkItem,
} from '@actions';
import WorkItemCard from '@components/WorkItemCard/WorkItemCard';
import useFilters from '@hooks/useFilters';
import GroupingFilters from '@components/GroupingNew/components/GroupingFilters/GroupingFilters';
import { generatePdfFromDoc } from '@components/GroupingNew/helpers';
import NewModal from '@components/Modal/NewModal';
import { WORK_ITEM_STATUSES } from '@constants';

import { closeGrouping, fetchFiltersData } from './api/api';

const ScrollingComponent = withScrolling('div');

const GroupingNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLeftSideLoading = useSelector(
    (state) => state?.groupingReducer?.leftSide?.isLoading,
  );
  const leftSideWI = useSelector(
    (state) => state?.groupingReducer?.leftSide?.data,
  );
  const currenWorkItems = useSelector(
    (state) => state?.groupingReducer?.rightSide?.data,
  );
  const isWorkItemsLoading = useSelector(
    (state) => state?.groupingReducer?.rightSide?.isLoading,
  );
  const readOnly =
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.rejected ||
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.approved ||
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.error ||
    leftSideWI?.[0]?.status?.name === WORK_ITEM_STATUSES.completed;

  const [filtersData, setFiltersData] = useState({
    batchOptions: [],
    emailOptions: [],
    loadNumbersOptions: [],
    subjectLineOptions: [],
    isLoading: true,
  });
  const [selectedPage, setSelectedPage] = useState({});
  const [filters, updateFilters] = useFilters({
    grouping: true,
  });
  const [selectedPages, setSelectedPages] = useState({
    documentId: '',
    pages: [],
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [involvedWiIds, setInvolvedWiIds] = useState([]);
  const [emptyWiIds, setEmptyWiIds] = useState([]);
  const addUniqueInvolvedWiId = (wiIds) => {
    setInvolvedWiIds((prevIds) => [...new Set([...prevIds, ...wiIds])]);
  };

  const fetchCleanupEmptyWorkItems = async (wiIds) => {
    const response = await api
      .restAPI()
      .get(`/work_items/cleanup_empty?id[]=${wiIds.join('&id[]=')}`);
    return response.data;
  };

  const onPageClick = (page) => {
    setSelectedPage(page);
  };

  const proceedWithClose = () => {
    dispatch(resetWorkItem());
    dispatch(resetGrouping());
    navigate(`/workitems/${leftSideWI?.[0].id}`);
  };

  const onClosePage = async () => {
    if (involvedWiIds.length > 0) {
      const data = await fetchCleanupEmptyWorkItems(involvedWiIds);
      if (data.length > 0) {
        setEmptyWiIds(data);
        setRemoveModalVisible(true);
      } else {
        proceedWithClose();
      }
    } else {
      proceedWithClose();
    }
  };
  const onDocumentTypeChange = ({ selectedType, documentId, wiId, from }) => {
    dispatch(changeDocumentType({ selectedType, documentId, wiId, from }));
  };
  const onDocumentRemove = (removedDocId, from, wiId) => {
    dispatch(removeDocument(removedDocId, from, wiId));
    addUniqueInvolvedWiId([wiId]);
  };

  const onSelectPage = ({ pageId, documentId }) => {
    if (selectedPages.documentId !== documentId) {
      setSelectedPages({
        documentId,
        pages: [pageId],
      });
    } else {
      const newPages = [...selectedPages.pages];
      if (!newPages.includes(pageId)) {
        setSelectedPages({
          documentId,
          pages: [...selectedPages.pages, pageId],
        });
      } else {
        newPages.splice(newPages.indexOf(pageId), 1);
        setSelectedPages({
          documentId,
          pages: newPages,
        });
      }
    }
  };

  const onWorkItemOpen = (wi) => {
    if (wi.documents === undefined) {
      dispatch(getWorkItemDocumentsById(wi.id));
    }
  };
  const onDocumentDrop = (dragInfo) => {
    if (dragInfo.newWorkItemId !== dragInfo.oldWorkItemId) {
      dispatch(onDocumentDropAction(dragInfo));
      addUniqueInvolvedWiId([dragInfo.oldWorkItemId]);
    }
  };

  const onAddUnassignedDocument = ({ wiId, from }) => {
    dispatch(onAddUnassignedDocumentAction({ wiId, from }));
  };

  const onMovePageToNewDocument = (dragInfo) => {
    unSelectSelectedPages();
    dispatch(onMovePageToNewDocumentAction(dragInfo));
    addUniqueInvolvedWiId([dragInfo.oldWIId]);
  };

  const onPageDrop = (dragInfo) => {
    unSelectSelectedPages();
    dispatch(onPageDropAction(dragInfo));
    if (dragInfo.newWorkItemId !== dragInfo.oldWorkItemId) {
      addUniqueInvolvedWiId([dragInfo.oldWorkItemId]);
    }
  };

  const unSelectSelectedPages = () => {
    setSelectedPages({
      documentId: '',
      pages: [],
    });
  };

  const onWorkItemRemove = (wi) => {
    if (wi.id && wi.from) {
      dispatch(onRemoveWorkItemAction(wi));
    }
  };

  const onRemoveClick = () => {
    emptyWiIds.forEach((wiId) => {
      dispatch(onRemoveWorkItemAction({ id: wiId, from: 'rightSide' }));
    });
    setRemoveModalVisible(false);
    proceedWithClose();
  };

  useEffect(() => {
    return () => {
      dispatch(resetWorkItem());
      dispatch(resetGrouping());
      closeGrouping(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroupingLeftSide(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    dispatch(getDocumentsTypes());
    setFiltersData({
      isLoading: true,
    });
    fetchFiltersData()
      .then((data) => {
        const {
          batchOptions,
          emailOptions,
          loadNumbersOptions,
          subjectLineOptions,
        } = data;
        setFiltersData({
          batchOptions,
          emailOptions,
          loadNumbersOptions,
          subjectLineOptions,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error('Error fetching filter options:', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      dispatch(getWorkItemByFilter(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const trigger = leftSideWI?.[0]?.id;

  useEffect(() => {
    if (leftSideWI?.[0]?.id) {
      setFirstLoad(false);
      updateFilters({
        'filter[batchId]': [Number(leftSideWI?.[0].batch?.data?.id)],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onAddNewWorkItem = () => {
    dispatch(addNewWorkItemAction(id))
      .then((newWorkItem) => {
        addUniqueInvolvedWiId([newWorkItem.id]);
      })
      .catch((error) => {
        console.error('Error adding new work item:', error);
      });
  };
  const onCloneWorkItem = (workItem) => {
    if (workItem?.id) {
      dispatch(cloneWorkItemAction(workItem.id));
    }
  };

  if (isLeftSideLoading) {
    return (
      <div className={s.loading}>
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className={s.wrapper}>
      <NewModal
        title="Delete empty work items?"
        submitButtonText="Delete"
        onCancel={() => proceedWithClose()}
        visible={removeModalVisible}
        onCrossClick={() => setRemoveModalVisible(false)}
        onSubmit={onRemoveClick}
      >
        Work items with no documents: <b>{emptyWiIds.join(', ')}</b>
      </NewModal>
      {selectedPage.src && (
        <Lightbox
          className={s.lightbox}
          medium={selectedPage.image_url}
          onClose={() => setSelectedPage({})}
        />
      )}
      <div className={s.header}>
        <div className={s.headerLeft}>
          <div className={s.title}>
            grouping (batch#{leftSideWI?.[0]?.batch?.data?.id})
          </div>
          <div className={s.companyName}>
            {leftSideWI?.[0]?.outputFields?.client?.name}
          </div>
          <div className={s.wiNumber}>WI#{leftSideWI?.[0]?.id}</div>
        </div>
        <CloseOutlined className={s.headerClose} onClick={onClosePage} />
      </div>
      <div className={s.content}>
        <ScrollingComponent className={s.contentLeft}>
          <div className={s.workItems}>
            {leftSideWI?.length > 0 &&
              leftSideWI?.map((mappedWorkItem) => (
                <WorkItemCard
                  from="leftSide"
                  defaultOpen
                  onWorkItemOpen={() => {}}
                  key={mappedWorkItem.id}
                  workItem={mappedWorkItem}
                  onDocumentDrop={onDocumentDrop}
                  onPageDrop={onPageDrop}
                  onWorkItemRemove={onWorkItemRemove}
                  onAddDocument={onAddUnassignedDocument}
                  onMovePageToNewDocument={onMovePageToNewDocument}
                  workItems={currenWorkItems}
                  onDownloadDocument={generatePdfFromDoc}
                  onPageClick={onPageClick}
                  onDocumentTypeChange={onDocumentTypeChange}
                  readOnly={readOnly}
                  onDocumentRemove={onDocumentRemove}
                  onSelectPage={onSelectPage}
                  selectedPages={selectedPages.pages}
                  onClone={onCloneWorkItem}
                />
              ))}
          </div>
        </ScrollingComponent>
        <div className={s.contentRight}>
          <div className={s.filtersHeader}>
            <GroupingFilters
              filtersData={filters}
              batchOptions={filtersData.batchOptions}
              emailsOptions={filtersData.emailOptions}
              loadNumbersOptions={filtersData.loadNumbersOptions}
              subjectLineOptions={filtersData.subjectLineOptions}
              updateFilters={updateFilters}
              disableFilters={isWorkItemsLoading || filtersData.isLoading}
            />
            <Button
              type="button"
              className={s.addButton}
              onClick={onAddNewWorkItem}
            >
              Add Work Item
            </Button>
          </div>

          <ScrollingComponent className={s.workItems}>
            {!currenWorkItems?.filter((wi) => wi.id !== leftSideWI?.[0].id)
              ?.length &&
              !isWorkItemsLoading && (
                <div className={s.loadingWrapper}>
                  No Work Items found those match search criteria
                </div>
              )}
            {isWorkItemsLoading && (
              <div className={s.loadingWrapper}>
                <Spin />
              </div>
            )}
            {currenWorkItems?.length > 0 &&
              [...currenWorkItems]
                .filter(
                  (currentWorkItem) =>
                    parseInt(currentWorkItem.id, 10) !==
                      parseInt(leftSideWI[0].id, 10) &&
                    !currentWorkItem?.discardedAt?.length,
                )
                .map((currentWorkItem) => (
                  <WorkItemCard
                    from="rightSide"
                    key={currentWorkItem.id}
                    workItem={currentWorkItem}
                    onDocumentDrop={onDocumentDrop}
                    onPageDrop={onPageDrop}
                    onWorkItemRemove={onWorkItemRemove}
                    onAddDocument={onAddUnassignedDocument}
                    onMovePageToNewDocument={onMovePageToNewDocument}
                    onWorkItemOpen={onWorkItemOpen}
                    workItems={currenWorkItems}
                    onDownloadDocument={generatePdfFromDoc}
                    onPageClick={onPageClick}
                    onDocumentTypeChange={onDocumentTypeChange}
                    onDocumentRemove={onDocumentRemove}
                    removable
                    onSelectPage={onSelectPage}
                    selectedPages={selectedPages.pages}
                    onClone={onCloneWorkItem}
                  />
                ))}
          </ScrollingComponent>
        </div>
      </div>
    </div>
  );
};

export default GroupingNew;
