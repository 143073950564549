import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import Login from '@components/Login';
import useCurrentUser from '@hooks/useCurrentUser';
import ResetPassword from '@components/ResetPassword';
import NewPassword from '@components/NewPassword';
import ProtectedRoute from '@services/ProtectedRoute';

import DocumentPage from './pages/DocumentPage';
import DashboardsPage from './pages/DashboardsPage';
import GroupingPage from './pages/GroupingPage';
import AdminPage from './pages/AdminPage';
import BatchPage from './pages/BatchPage';
import WorkItemPage from './pages/WorkItemPage';
import MainPage from './pages/MainPage';
import UnauthorizedPage from './pages/UnauthorizedPage';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export default function App() {
  const [redirect, setRedirect] = useState(null);
  const location = useLocation();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (
      !currentUser &&
      !redirect &&
      location.pathname !== '/login' &&
      location.pathname !== '/restore-password' &&
      location.pathname !== '/reset-password' &&
      redirect !== location.pathname
    ) {
      setRedirect(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser?.timezone) {
      dayjs.tz.setDefault(currentUser?.timezone);
    }
  }, [currentUser]);

  const routes = [
    { path: '/login', element: <Login redirect={redirect} /> },
    { path: '/restore-password', element: <NewPassword /> },
    { path: '/reset-password', element: <ResetPassword /> },
    { path: '/unauthorized', element: <UnauthorizedPage /> },
    { path: '*', element: <Navigate to="/login" /> },
    {
      element: <ProtectedRoute />,
      children: [
        { path: '/', element: <MainPage /> },
        { path: '/workitems/:id', element: <WorkItemPage /> },
        { path: '/batch/:id', element: <BatchPage /> },
        { path: 'admin/*', element: <AdminPage /> },
        { path: '/grouping/:id', element: <GroupingPage /> },
        { path: '/dashboard', element: <DashboardsPage /> },
        { path: '/workitems/:id/:documentId', element: <DocumentPage /> },
      ],
    },
  ];
  useEffect(() => {
    if (redirect && redirect === location.pathname) {
      setRedirect(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <div>
      <Routes>
        {routes.map((route) => {
          if (route.path) {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            );
          }
          return (
            <Route element={route.element} key={route.element}>
              {route.children.map((child) => (
                <Route
                  key={child.path}
                  path={child.path}
                  element={child.element}
                />
              ))}
            </Route>
          );
        })}
      </Routes>
    </div>
  );
}
